/* Функции */
import { defineComponent, VNode } from 'vue'

/* Компоненты */
import VFullScreen from '@/components/VFullScreen/VFullScreen'
import VContent from '@/components/VContent/VContent'
import VRow from '@/components/VRow/VRow'
import VIcon from '@/components/VIcon/VIcon'
import VButton from '@/components/VButton/VButton'
import VTopBackground from '@/components/VTopBackground/VTopBackground'
import VTextHeading1 from '@/components/VText/VTextHeading1/VTextHeading1'
import VTextInput from '@/components/VInput/VTextInput/VTextInput'
import TheAuth from '@/components/TheAuth/TheAuth'
import VTextSmall from '@/components/VText/VTextSmall/VTextSmall'
import VTransition from '@/components/VTransition/VTransition'
import BaseAuthModeToggle from '@/components/BaseAuthModeToggle/BaseAuthModeToggle'

/* Стили */
import '@/views/Login/Login.scss'

/* Типы */
import { Data } from '@/views/Login/Types'
import { validEmail, validPhone } from '@/components/Validation/Rules'
import { RoutePaths } from '@/router/Types'
import { AuthMode } from '@/components/TheAuth/Types'
import { MaskPhone } from '@/types/constants/common'

/* Классы */
import { RequestAPI } from '@/classes/Request'

export default defineComponent({
  name: 'LoginView',

  data(): Data {
    return {
      email: '',
      phone: '',
      isShowCodeModal: false,
      authMode: AuthMode.phone,
    }
  },

  computed: {
    /** Признак блокировки кнопки */
    isDisabledButton(): boolean {
      switch (this.authMode) {
        case AuthMode.email:
          return !(Boolean(this.email) && Boolean(validEmail()(this.email)))

        case AuthMode.phone:
          return !(Boolean(this.phone) && Boolean(validPhone()(this.phone)))
      }

      return true
    },
  },

  methods: {
    /** Клик назад */
    onClickBack() {
      this.$router.back()
    },

    /** Клик по входу */
    async login() {
      try {
        const requestData: { email?: string, phone?: string } = {}

        if (this.authMode === AuthMode.email) {
          requestData.email = this.email
        }

        if (this.authMode === AuthMode.phone) {
          requestData.phone = this.phone
        }

        const response = await RequestAPI.errorHandler(RequestAPI.post, '/athletes/sign_in', {
          ...requestData,
        })

        if (response?.data?.response.status === 'ok') {
          this.isShowCodeModal = true
        }
      } catch (error) {
        console.error(error)
      }
    },
  },

  render(): VNode {
    const header = () => (
      <div class={'header-login'}>
        <VRow justify='space-between'>
          <VIcon imageName={'arrow_left'} onClick={this.onClickBack} />
        </VRow>
      </div>
    )

    const content = () => (
      <div key={'login'} class={'login'}>
        <VTopBackground imageName={'registration'} zIndex={0} isShowDesktopImg={true} />

        <VRow>
          <VTextHeading1>{this.$t('login.title')}</VTextHeading1>
        </VRow>

        <BaseAuthModeToggle
          phoneText={this.$t('login.toggle.phone')}
          emailText={this.$t('login.toggle.email')}
          onUpdate:modelValue={(value) => { this.authMode = value }}
        />

        <VTransition>
          {
            this.authMode === AuthMode.phone
              ? <VTextInput
                key={'phone'}
                subtextAlign={'left'}
                label={this.$t('login.fields.phone.label')}
                onUpdate:modelValue={(value) => { this.phone = value }}
                modelValue={this.phone}
                mask={MaskPhone}
                onEnter={() => {
                  if (!this.isDisabledButton) { this.login() }
                }}
                autofocus={true}
                subtext={this.$t('login.fields.phone.subtext')}
                inputmode={'tel'}
              />
              : <VTextInput
                key={'email'}
                subtextAlign={'left'}
                label={this.$t('login.fields.email.label')}
                onUpdate:modelValue={(value) => { this.email = value }}
                modelValue={this.email}
                onEnter={() => {
                  if (!this.isDisabledButton) { this.login() }
                }}
                autofocus={true}
                subtext={this.$t('login.fields.email.subtext')}
                inputmode={'email'}
              />
          }
        </VTransition>
      </div>
    )

    return (
      <VFullScreen class={'login-view'}>
        <TheAuth
          isSecondaryModal={true}
          isShow={this.isShowCodeModal}
          onOnClose={(value) => { this.isShowCodeModal = value }}
          authMode={this.authMode}
          email={this.email}
          phone={this.phone}
        />

        <VContent>
          {{
            top: () => [
              header(),
              content(),
            ],

            bottom: () => [
              <VButton
                isDisabled={this.isDisabledButton}
                onClick={this.login}
              >
                {this.$t('login.buttons.sendLink')}
              </VButton>,

              <VRow justify={'center'}>
                <VTextSmall
                  onClick={() => {
                    this.$router.push(RoutePaths.quiz)
                  }}
                  cursor={'pointer'}
                >
                  {this.$t('login.buttons.registration')}
                </VTextSmall>
              </VRow>,
            ],
          }}
        </VContent>
      </VFullScreen>
    )
  },
})
