/* Функции */
import {
  defineComponent, HTMLAttributes, InputTypeHTMLAttribute, PropType, ref, VNode,
} from 'vue'

/* Компоненты */
import VInputLabel from '@/components/VInput/VInputLabel/VInputLabel'
import VTextSmall from '@/components/VText/VTextSmall/VTextSmall'

/* Типы */
import { TextAlign } from '@/components/VText/Types'
import { vMaska } from 'maska'

/* Стили */
import '@/components/VInput/VTextInput/VTextInput.scss'

export default defineComponent({
  name: 'VTextInput',

  directives: { maska: vMaska },

  emits: {
    /** Событие обновления значения */
    'update:modelValue': (value: string) => typeof value === 'string',
    /** Событие нажатия enter */
    enter: (value: boolean) => typeof value === 'boolean',
    /** Событие нажатия вставки */
    paste: () => true,
  },

  props: {
    /** Ярлык */
    label: {
      type: String as PropType<string>,
      default: '',
    },

    /** Подпись */
    subtext: {
      type: String as PropType<string>,
      default: '',
    },

    /** Значение атрибута placeholder */
    placeholder: {
      type: String as PropType<string>,
      default: '',
    },

    /** Отключение поля */
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    /** Автоматический фокус */
    autofocus: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    /** Значения для связывания */
    modelValue: {
      type: String as PropType<string>,
      default: '',
    },

    /** Функция валидации */
    validation: {
      type: Function as PropType<(value: string | number) => boolean>,
      default: () => false,
    },

    /** Выравнивание подписи */
    subtextAlign: {
      type: String as PropType<TextAlign>,
      default: 'center',
    },

    /** Есть лли граница */
    isBorder: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    /** Тип */
    type: {
      type: String as PropType<InputTypeHTMLAttribute>,
      default: 'text',
    },

    /** Маска */
    mask: {
      type: String as PropType<InputTypeHTMLAttribute>,
      default: '',
    },

    /** Режим ввода данных */
    inputmode: {
      type: String as PropType<HTMLAttributes['inputmode']>,
      default: 'text',
    },
  },

  methods: {
    /** Обработчик события ввода */
    onInput(event: Event) {
      const target = event.target as HTMLInputElement
      const { value } = target

      this.$emit('update:modelValue', value ?? '')
    },

    /** Метод валидации. Должен быть доступен извне */
    validate(value: string) {
      const validationResult = this.validation(value)

      return validationResult
    },

    /** Метод обработки кнопки Enter */
    onKeyup(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        this.$emit('enter', true)
      }
    },

    /** Метод обработки события вставки текста */
    onPaste(event: ClipboardEvent) {
      const text = event.clipboardData?.getData('text')

      if (text) {
        this.$nextTick(() => {
          this.$emit('paste')
        })
      }
    },
  },

  computed: {
    classes() {
      return {
        'is-border': this.isBorder,
        'text-input': true,
        disabled: this.disabled,
      }
    },
  },

  watch: {
    modelValue() {
      this.validate(this.modelValue)
    },
  },

  mounted() {
    if (this.autofocus) {
      this.$nextTick(() => this.inputField?.focus())
    }
  },

  setup() {
    const inputField = ref<HTMLInputElement>()

    return {
      inputField,
    }
  },

  render(): VNode {
    return (
      <div class={this.classes}>
        <VInputLabel>{this.label}</VInputLabel>

        {
          this.mask
            ? <input
              ref={'inputField'}
              class={'text-input__field'}
              type={this.type}
              placeholder={this.placeholder}
              autofocus={this.autofocus}
              value={this.modelValue}
              disabled={this.disabled}
              onInput={this.onInput}
              onKeyup={this.onKeyup}
              onPaste={this.onPaste}
              v-maska
              data-maska={this.mask}
              inputmode={this.inputmode}
            />
            : <input
              ref={'inputField'}
              class={'text-input__field'}
              type={this.type}
              placeholder={this.placeholder}
              autofocus={this.autofocus}
              value={this.modelValue}
              disabled={this.disabled}
              onInput={this.onInput}
              onKeyup={this.onKeyup}
              onPaste={this.onPaste}
              inputmode={this.inputmode}
            />
        }

        <VTextSmall textAlign={this.subtextAlign} class={'text-input__subtext'}>{this.subtext}</VTextSmall>
      </div>
    )
  },
})
