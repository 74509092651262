/* Функции */
import { defineComponent, VNode } from 'vue'

/* Компоненты */
import VPage from '@/components/VPage/VPage'
import VContentItem from '@/components/VContent/VContentItem/VContentItem'
import VTextParagraph from '@/components/VText/VTextParagraph/VTextParagraph'
import VButton from '@/components/VButton/VButton'

/* Стили */
import '@/views/MyGoals/MyGoalsDetailClose/MyGoalsDetailClose.scss'

/* Типы */
import { RoutePaths } from '@/router/Types'
import { ResponseMyGoalDetail } from '@/types/API'
import { RequestAPI } from '@/classes/Request'
import { Data } from '@/views/MyGoals/MyGoalsDetailClose/Types'

/* Хранилище */
import { LayoutStore } from '@/store/layout'

export default defineComponent({
  name: 'MyGoalsDetailCloseView',

  data(): Data {
    return {
      target: null,
    }
  },

  computed: {
    /** Ссылка */
    back(): string {
      return RoutePaths.myGoalsDetail.replace(':id', String(this.$route.params.id))
    },

    /** Название плана */
    planName(): string {
      return this.target?.title ?? ''
    },
  },

  methods: {
    async closePlan() {
      const response = await RequestAPI.errorHandler<ResponseMyGoalDetail>(RequestAPI.delete, `/athletes/goals/${this.$route.params.id}`)

      if (response) {
        this.$router.replace(RoutePaths.goals)
      }
    },

    /** Получение данных по плану */
    async getPlans() {
      const response = await RequestAPI.errorHandler<ResponseMyGoalDetail>(RequestAPI.get, `/athletes/goals/${this.$route.params.id}`)

      if (response) {
        return response.data.content
      }

      return null
    },

    /** Установка данных */
    async setData() {
      const data = await this.getPlans()

      if (data) {
        const { goal } = data
        this.target = goal ?? null
      }
    },
  },

  async created() {
    LayoutStore().setLoading(true)

    await this.setData()

    LayoutStore().setLoading(false)
  },

  render(): VNode | null {
    return (
      this.target
        ? <VPage class={'my-goals-detail-close'} key={'MyGoalsDetailClose'}>
          <VContentItem isPaddingX={true}>
            <VTextParagraph opacity={1}>
              {
                this.planName
                  ? this.$t('pages.MyGoalsDetailClose.description', { name: this.planName })
                  : this.$t('pages.MyGoalsDetailClose.descriptionNoPlanName')
              }
            </VTextParagraph>
          </VContentItem>

          <VContentItem isPaddingX={true}>
            <VButton isQuaternary={true} onClick={this.closePlan}>
              {this.$t('pages.MyGoalsDetailClose.buttonClose')}
            </VButton>

            <VButton isTertiary={true} onClick={() => this.$router.push(this.back)}>
              {this.$t('pages.MyGoalsDetailClose.buttonCancel')}
            </VButton>
          </VContentItem>
        </VPage>
        : null
    )
  },
})
