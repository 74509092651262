import i18n from '@/locales'
import { RoutePaths } from '@/router/Types'
import { ProfileAthlete } from '@/types/API'

export interface Data {
  profileData: ProfileAthlete | null,
  isLoadData: boolean
}

/** Перечисление маршрутов профиля */
export enum RoutePathsProfile {
  /** Персональная информация */
  personalInformation = '/profile/personal-information',
  /** Мои данные */
  myData = '/profile/my-data',
  /** Цели */
  goals = '/profile/goals',
  /** Оборудование */
  equipment = '/profile/equipment',
  /** История тренировок */
  trainingHistory = '/profile/training-history',

  /* План */
  plan = '/profile/plan',
  /* Телеграмм бот */
  telegramBot = '/profile/telegram-bot',

  /** Информация */
  info = '/profile/info',

  /** Политика */
  infoPolitics = '/profile/info/politics',
  /** Соглашение */
  infoAgreement = '/profile/info/agreement',
}

export type ProfileMenuItem = {
  title: string,
  link: RoutePathsProfile | RoutePaths,
  description?: string,
  disabled?: boolean,
}

export const ProfileMenu: ProfileMenuItem[][] = [
  // Блок 1
  [
    {
      title: i18n.global.t('pages.Profile.menu.personalInformation.title'),
      link: RoutePathsProfile.personalInformation,
    },
    {
      title: i18n.global.t('pages.Profile.menu.myData.title'),
      link: RoutePathsProfile.myData,
    },
    {
      title: i18n.global.t('pages.Profile.menu.goals.title'),
      link: RoutePathsProfile.goals,
      disabled: true,
    },
    {
      title: i18n.global.t('pages.Profile.menu.equipment.title'),
      link: RoutePathsProfile.equipment,
    },
    {
      title: i18n.global.t('pages.Profile.menu.trainingHistory.title'),
      link: RoutePathsProfile.trainingHistory,
      disabled: true,
    },
  ],

  // Блок 2
  [
    {
      title: i18n.global.t('pages.Profile.menu.plan.title'),
      link: RoutePaths.subscriptions,
    },
    {
      title: i18n.global.t('pages.Profile.menu.telegramBot.title'),
      description: i18n.global.t('pages.Profile.menu.telegramBot.description'),
      link: RoutePathsProfile.telegramBot,
    },
  ],

  // Блок 3
  [
    {
      title: i18n.global.t('pages.Profile.menu.info.title'),
      link: RoutePathsProfile.info,
    },
  ],
]

export const InfoMenu: ProfileMenuItem[][] = [
  [
    {
      title: i18n.global.t('pages.Profile.info.menu.infoPolitics'),
      link: RoutePathsProfile.infoPolitics,
    },
    {
      title: i18n.global.t('pages.Profile.info.menu.infoAgreement'),
      link: RoutePathsProfile.infoAgreement,
    },
  ],
]
