/* Функции */
import { defineComponent, VNode } from 'vue'

/* Компоненты */
import VPage from '@/components/VPage/VPage'
import VContentItem from '@/components/VContent/VContentItem/VContentItem'
import VRow from '@/components/VRow/VRow'
import VTextInput from '@/components/VInput/VTextInput/VTextInput'
import VButtonFixes from '@/components/VButton/VButtonIcon/VButtonFixes/VButtonFixes'
import VTextButton from '@/components/VText/VTextButton/VTextButton'
import VTransition from '@/components/VTransition/VTransition'

/* Типы */
import { RequestAPI } from '@/classes/Request'
import { Profile } from '@/types/API'
import { Data } from '@/views/Profile/PersonalInformation/Types'
import { isRussianPhone } from '@/helpers/common'
import { MaskPhone } from '@/types/constants/common'

/* Хранилище */
import { LayoutStore } from '@/store/layout'

export default defineComponent({
  name: 'PersonalInformationPage',

  data(): Data {
    return {
      email: '',
      name: '',
      lastName: '',
      phone: '',
      isLoadData: false,
      isSaved: false,
      startData: {
        email: '',
        name: '',
        lastName: '',
        phone: '',
      },
      isDisabledPhone: false,
    }
  },

  computed: {
    isChange(): boolean {
      const {
        name, phone, lastName, startData,
      } = this

      return startData.name !== name || startData.phone !== phone || startData.lastName !== lastName
    },

    isValid(): boolean {
      if (this.phone.length) {
        return Boolean(this.name && isRussianPhone(this.phone))
      }
      return Boolean(this.name)
    },
  },

  methods: {
    updateStartData() {
      this.startData = {
        email: this.email,
        name: this.name,
        lastName: this.lastName,
        phone: this.phone,
      }
    },

    async save() {
      LayoutStore().setLoading(true)

      const {
        name, lastName,
      } = this
      this.isSaved = true

      await RequestAPI.errorHandler<Profile>(RequestAPI.put, '/profile', {
        athlete: {
          first_name: name,
          last_name: lastName,
        },
      })

      this.updateStartData()

      LayoutStore().setLoading(false)
      this.isSaved = false
    },
  },

  async created() {
    LayoutStore().setLoading(true)

    const response = await RequestAPI.errorHandler<Profile>(RequestAPI.get, '/profile')

    if (response) {
      this.email = response.data.content.athlete.email
      this.phone = response.data.content.athlete.phone
      this.name = response.data.content.athlete.first_name ?? ''
      this.lastName = response.data.content.athlete.last_name ?? ''
      this.isDisabledPhone = Boolean(this.phone)

      this.updateStartData()
    }

    LayoutStore().setLoading(false)
    this.isLoadData = true
  },

  render(): VNode {
    return (
      <VPage>
        <VContentItem isPaddingX={true} isShow={this.isLoadData}>
          <VRow>
            <VTextInput
              subtextAlign={'left'}
              isBorder={true}
              label={this.$t('pages.Profile.personalInformation.fields.name.label')}
              onUpdate:modelValue={(value) => { this.name = value }}
              modelValue={this.name}
            />
          </VRow>

          <VRow>
            <VTextInput
              subtextAlign={'left'}
              isBorder={true}
              label={this.$t('pages.Profile.personalInformation.fields.lastName.label')}
              onUpdate:modelValue={(value) => { this.lastName = value }}
              modelValue={this.lastName}
            />
          </VRow>

          <VRow>
            <VTextInput
              subtextAlign={'left'}
              isBorder={true}
              label={this.$t('pages.Profile.personalInformation.fields.phone.label')}
              onUpdate:modelValue={(value) => { this.phone = value }}
              modelValue={this.phone}
              mask={MaskPhone}
              disabled={this.isDisabledPhone}
              inputmode={'tel'}
            />
          </VRow>

          <VRow>
            <VTextInput
              subtextAlign={'left'}
              isBorder={true}
              label={this.$t('pages.Profile.personalInformation.fields.email.label')}
              onUpdate:modelValue={(value) => { this.email = value }}
              modelValue={this.email}
              disabled={true}
              inputmode={'email'}
            />
          </VRow>

          <VTransition>
            {
              this.isChange
                ? <VButtonFixes
                  isFullWidth={true}
                  isDisabled={this.isSaved || !this.isValid}
                  onClick={this.save}>
                  <VTextButton>{this.$t('pages.Profile.personalInformation.button')}</VTextButton>
                </VButtonFixes>
                : null
            }
          </VTransition>
        </VContentItem>
      </VPage>
    )
  },
})
