/* Функции */
import {
  AnchorHTMLAttributes, defineComponent, PropType,
} from 'vue'

/* Компоненты */

/* Стили */
import '@/components/VLink/VLink.scss'

/* Типы */
import { defaultProps, defaultStyles } from '@/components/VText/Types'

export default defineComponent({
  name: 'VLink',

  props: {
    ...defaultProps(),

    /** Тег */
    tag: {
      type: String as PropType<keyof HTMLElementTagNameMap>,
      default: 'a',
    },

    /** Способ открытия ссылки */
    target: {
      type: String as PropType<'_self' | '_blank' | '_parent' | '_top'>,
      default: '_self',
    },

    /** Путь ссылки */
    to: {
      type: String as PropType<AnchorHTMLAttributes['href']>,
      required: true,
    },

    /** Является ли ссылка роутером */
    isRouterLink: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  computed: {
    styles() {
      return {
        ...defaultStyles.call(this),
      }
    },
  },

  render() {
    const nameComponent = this.tag
    const content = () => (!this.isRouterLink ? <nameComponent
      onClick={this.onClick}
      class={'text text-link'}
      target={this.target}
      style={this.styles}
      href={this.to}
    >
      {this.$slots.default?.()}
    </nameComponent>
      : <router-link
        style={this.styles}
        class={'text text-link text-link-router'}
        to={this.to}
      >
        {this.$slots.default?.()}
      </router-link>)

    return content()
  },
})
