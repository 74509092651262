/* Функции */
import { defineComponent, VNode } from 'vue'

import BasePageAction from '@/components/BasePageAction/BasePageAction'
import VButtonFixes from '@/components/VButton/VButtonIcon/VButtonFixes/VButtonFixes'
import VListIcons from '@/components/VListIcons/VListIcons'
import VTextButton from '@/components/VText/VTextButton/VTextButton'

/* Типы */
import { Data, QueryParams } from '@/views/Profile/TelegramBot/Types'
import { RequestAPI } from '@/classes/Request'
import { Profile } from '@/types/API'
import { RoutePaths } from '@/router/Types'

/* Хранилище */
import { LayoutStore } from '@/store/layout'

/* Компоненты */
export default defineComponent({
  name: 'TelegramBotPage',

  data(): Data {
    return {
      queryParams: null,
      isTelegramActive: false,
      telegramToken: null,
      isShowPage: false,
    }
  },

  computed: {
    items() {
      return this.$t('pages.Profile.telegramBot.items').split('*')
    },

    linkTelegram(): string {
      const bot = process.env.VUE_APP_TELEGRAM_BOT

      return `${bot}?start=${this.telegramToken}`
    },
  },

  async created() {
    LayoutStore().setLoading(true)

    const response = await RequestAPI.errorHandler<Profile>(RequestAPI.get, '/profile')

    if (response) {
      const { data } = response

      this.isTelegramActive = Boolean(data.content.athlete.telegram_id)
      this.telegramToken = data.content.athlete.telegram_token
    }

    LayoutStore().setLoading(false)
    this.isShowPage = true
  },

  render(): VNode {
    return (
      <BasePageAction
        isShow={this.isShowPage}
        title={this.$t('pages.Profile.telegramBot.title')}
        onClick={() => {
          const query = this.$route.query as Record<string, string>

          if (query[QueryParams.redirectBack]) {
            this.$router.replace({
              path: query[QueryParams.redirectBack],
            })
          } else {
            this.$router.push(RoutePaths.profile)
          }
        }}
      >
        <VListIcons items={this.items} />

        <VButtonFixes isFullWidth={true} isDisabled={this.isTelegramActive} onClick={() => { window.open(this.linkTelegram) }}>
          <VTextButton>{this.$t('pages.Profile.telegramBot.button', this.isTelegramActive ? 1 : 0)}</VTextButton>
        </VButtonFixes>
      </BasePageAction>
    )
  },
})
