/* Функции */
import { defineComponent, VNode } from 'vue'

/* Компоненты */
import BaseButtonMenuInline from '@/components/BaseButtonMenuInline/BaseButtonMenuInline'
import VContentItem from '@/components/VContent/VContentItem/VContentItem'
import VPage from '@/components/VPage/VPage'

/* Стили */
import '@/views/Profile/Info/Info.scss'

/* Типы */
import { InfoMenu } from '@/views/Profile/Types'

export default defineComponent({
  name: 'InfoView',

  render(): VNode {
    const menu = () => InfoMenu.map((section) => <div class={'profile-menu-section'}>{
      section.map((menuItem) => (
        <BaseButtonMenuInline
          description={menuItem.description}
          link={menuItem.link}
          disabled={menuItem.disabled}
        >
          {menuItem.title}
        </BaseButtonMenuInline>
      ))}</div>)

    return <VPage class="profile-info">
      <VContentItem isPaddingX={true} isShow={true}>
        {menu()}
      </VContentItem>
    </VPage>
  },
})
