import { createI18n, I18nOptions } from 'vue-i18n'
import { ru as pluralizationRulesLocales } from '@/helpers/pluralizationRulesLocales'

// Локализация
import ru from '@/locales/ru.json'

const i18nConfig: I18nOptions = {
  globalInjection: true,
  locale: 'ru',
  messages: {
    ru,
  },
  pluralRules: {
    ru: pluralizationRulesLocales,
  },
}

const i18n = createI18n(i18nConfig)

export default i18n
