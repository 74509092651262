/* Функции */
import { defineComponent, VNode } from 'vue'

/* Компоненты */
import VPage from '@/components/VPage/VPage'
import VRow from '@/components/VRow/VRow'
import VContentItem from '@/components/VContent/VContentItem/VContentItem'
import VTextParagraph from '@/components/VText/VTextParagraph/VTextParagraph'
import VTextHeading1 from '@/components/VText/VTextHeading1/VTextHeading1'

/* Стили */
import '@/views/Profile/Info/infoPolitics/infoPolitics.scss'

export default defineComponent({
  name: 'infoPoliticsView',

  render(): VNode {
    return <VPage>
      <VContentItem isPaddingX={true} isShow={true}>
        <VRow>
          <VTextHeading1 textAlign={'center'}>Политика обработки персональных данных ООО «БОДИ БАДИ»</VTextHeading1>
        </VRow>

        <VRow>
          <VTextParagraph>Нажимая кнопку «СОГЛАСЕН/УЧАСТВОВАТЬ/ПОЛУЧИТЬ», физическое лицо выражает согласие с настоящей Политикой обработки персональных данных.</VTextParagraph>
        </VRow>

        <VRow>
          <VTextParagraph>Персональные данные физическое лицо сообщает самостоятельно путем заполнения поля «e-mail» и/или телефон и/или ID Telegram на формах регистрации,  доступа в личный кабинет сервиса, а также формах обратной связи на сайте https://sport.b-b.fit (а также в Чат-боте Telegram @SportAppyBot) и подтверждает, что:</VTextParagraph>
        </VRow>

        <ul>
          <li><VTextParagraph>указанные им данные и адрес электронной почты принадлежат лично ему и передаются в связи с заинтересованностью получить информацию об услугах ООО «БОДИ БАДИ» ОГРН 1227700515591 и последующего их заказа;</VTextParagraph></li>
          <li><VTextParagraph>дает согласие на обработку ООО «БОДИ БАДИ» введенных физическим лицом данных;</VTextParagraph></li>
          <li><VTextParagraph>данные направлены физическим лицом с целями, исключающими причинение вреда ООО «БОДИ БАДИ» ОГРН 1227700515591, оскорбление, внедрение вредоносных программ, сбор информации о ООО «БОДИ БАДИ».</VTextParagraph></li>
        </ul>

        <VRow>
          <VTextParagraph>Обработка персональных данных осуществляется с целью последующего оказания услуг физическому лицу. Обработка осуществляется ООО «БОДИ БАДИ» (адрес: 105215, РОССИЯ, г. Москва, МУНИЦИПАЛЬНЫЙ ОКРУГ СЕВЕРНОЕ ИЗМАЙЛОВО, Улица Константина Федина, д. 9, 59).</VTextParagraph>
        </VRow>

        <VRow>
          <VTextParagraph>Настоящее согласие предоставлено физическим лицом в отношении персональных данных, содержащихся в форме обратной связи – имя и фамилия, пол, возраст, рост, вес, параметры физической формы и состояния,  адрес электронной почты, телефон, ID Telegram, а также: IP-адрес, файлы cookie, геопозиция (город, область местонахождения ЭВМ, с которой было отправлено сообщение).</VTextParagraph>
        </VRow>

        <VRow>
          <VTextParagraph>Способы обработки персональных данных, на которые физическое лицо дает согласие: сбор, хранение, отслеживание актуальности, сортировка, использование для связи с лицом, сообщившим данные. Компания гарантирует, что персональные данные физических лиц  не будут распространяться и предоставляться третьим лицам без их согласия и будут обрабатываться только в целях оказания услуг данному физическому лицу.</VTextParagraph>
        </VRow>

        <VRow>
          <VTextParagraph>Данное согласие действует в течение срока существования ООО «БОДИ БАДИ» или их правопреемников. Согласие может быть отозвано.</VTextParagraph>
        </VRow>
      </VContentItem>
    </VPage>
  },
})
