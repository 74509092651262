export interface Data {
  code: string
}

export enum AuthMode {
  email = 'email',
  phone= 'phone'
}

/** Длина кода */
export const CodeLength = 6
